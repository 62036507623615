<template>
  <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
    <ion-refresher-content 
      :pulling-icon="chevronDownCircleOutline"
      refreshing-spinner="circles"
      refreshing-text="로딩중..."
    ></ion-refresher-content>
  </ion-refresher>
  <ion-list>
    <ion-item-sliding>
      <template v-if="total > 0">
        <ion-item v-for="(item, index) in list.datas" :key="index">
          <ion-label>
            <h1>{{ item.title }}</h1>
            <span class="date">
              <slot name="regDate" :item="item" :index="index"></slot>
            </span>
          </ion-label>
          <slot name="rightButton" :item="item" :index="index" />
        </ion-item>
      </template>
      <!-- <template v-else>
        <ion-item>
          <ion-label>
            <h2>등록된 데이터가 없습니다.</h2>
          </ion-label>
        </ion-item>
      </template> -->
    </ion-item-sliding>
  </ion-list>

  <ion-infinite-scroll
    @ionInfinite="loadData($event)" 
    threshold="600px" 
    id="infinit6-scroll"
    :disabled="!nextLoading"
  >
    <ion-infinite-scroll-content
      loading-spinner="bubbles"
      loading-text="로딩중...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</template>

<script>
import { 
  IonList,
  IonItemSliding,
  IonItem,
  // IonAvatar,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue'
import { defineComponent, ref, reactive, watch } from 'vue'
import { chevronDownCircleOutline } from 'ionicons/icons'

export default defineComponent({
  name: 'List',
  components: { 
    IonList,
    IonItemSliding,
    IonItem,
    // IonAvatar,
    IonLabel,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    // IonModal,
    // Modal 
  },
  props: {
    name: {
      type: String,
      default: undefined
    },
    datas: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    pageNum: {
      type: Number,
      default: 0
    },
    nextLoading: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    let list = reactive({
      datas: []
    })
    let detailTitle = reactive(undefined)
    let detailData = ref({})

    watch(() => props.datas, (newVal) => {
      newVal.map(o => o.profileImage = require('./avatar-rey.png'))
      list.datas = newVal
    },
    { 
      deep: true 
    })

    const pushData = () => {
      if (props.nextLoading) {
        emit('nextPage', Number(props.pageNum) + 1)
      }
    }

    const loadData = (ev) => {
      setTimeout(() => {
        if (props.nextLoading) {
          pushData()
        }
        ev.target.complete()
      }, 5)
    }

    const doRefresh = (event) => {
      setTimeout(() => {
        event.target.complete()
      }, 2000)
    }

    return { 
      list,
      detailTitle,
      detailData,
      loadData,
      chevronDownCircleOutline,
      doRefresh
    }
  }
})
</script>

<style scoped>
.date{
  display: block;
  font-size: 14px;
  color: #666;
  font-weight: normal;
}
:deep ion-item{
  --min-height: 65px;
  --padding-start: 0;
  --inner-padding-end: 0;
  /* --border-color: transparent; */
}
:deep ion-item > ion-label > h1 {
  font-size: 16px;
  font-weight: bold;
}

</style>
